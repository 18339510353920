<template>
  <v-card class="mx-auto">
    <v-card-title>
      <v-icon
        large
        left
        color="#26c6da"
      >
        mdi-twitter
      </v-icon>
    </v-card-title>
    <v-col cols="12">
      <timeline
        :id="user_id"
        source-type="profile"
        :options="{ tweetLimit: '0',chrome:'nofooter' }"
      />
    </v-col>
  </v-card>
</template>

<script>
  import { Timeline } from 'vue-tweet-embed'
  export default {
    name: 'EdenTimeline',
    components: {
      Timeline,
    },
    data: function () {
      return {
        user_id: 'ff14housingeden',
      }
    },
  }
</script>
